import { request, noTimeOutReq } from '@/utils/request.js'

// 展示某个考点下面所有【考点教室】
export function roomList (sysOrgExamPlaceId = 10000) {
  return noTimeOutReq({
    url: `/sys/org/exam/place/room/place/${sysOrgExamPlaceId}/list`,
    method: 'post'
  })
}

// 获取考点列表
export function placeExamList () {
  return request({
    url: `/explore/index/place/exam/list`,
    method: 'post'
  })
}

// 获取首页统计数据
export function statisticsList () {
  return request({
    url: `/explore/index/statistics/get`,
    method: 'post'
  })
}
