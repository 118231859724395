<template>
  <div class="homeBox">
    <div class="homeContent">
      <!-- 地图 -->
      <!-- <div class="homeMapBox" :style="'transform:scale('+scaleNun+')'"> -->
      <div class="homeMapBox">
        <div class="homeMap">
          <Map :coordinateTagData="coordinateTagData" @tagClick="tagClick" />
        </div>
        <!-- <i
          class="el-icon-location-outline"
          v-for="(val,i) in coordinateData"
          :key="i"
          @click="boxCard = true"
          :style="'top:calc(50% - '+val.y+'px);left:calc(50% - '+val.x+'px);'"
        >
          <span>{{val.name}}</span>
        </i>-->
      </div>
    </div>
    <div class="homeUl">
      <div class="homeLi">
        <div class="homeLiLeft">
          <div class="homeLiLeftTit">学生数量</div>
          <div class="homeLiLeftContent">
            {{ statisticsData.studentCount }}
            <span>人</span>
          </div>
        </div>
        <img src="../../assets/home/homeTit1.png" />
      </div>
      <!-- <div class="homeLi">
        <div class="homeLiLeft">
          <div class="homeLiLeftTit">教师数量</div>
          <div class="homeLiLeftContent">
            {{statisticsData.studentCount}}
            <span>人</span>
          </div>
        </div>
        <img src="../../assets/home/homeTit1.png" />
      </div>-->
      <div class="homeLi">
        <div class="homeLiLeft">
          <div class="homeLiLeftTit">用户数量</div>
          <div class="homeLiLeftContent">
            {{ statisticsData.userCount }}
            <span>人</span>
          </div>
        </div>
        <img src="../../assets/home/homeTit2.png" />
      </div>
      <div class="homeLiTextTop">市级平台实验考试系统</div>
      <!-- <div class="homeLi">
        <div class="homeLiLeft">
          <div class="homeLiLeftTit">学校数量</div>
          <div class="homeLiLeftContent">
            {{statisticsData.examPlaceCount}}
            <span>个</span>
          </div>
        </div>
        <img src="../../assets/home/homeTit3.png" />
      </div>-->
      <div class="homeLi">
        <div class="homeLiLeft">
          <div class="homeLiLeftTit">考点数量</div>
          <div class="homeLiLeftContent">
            {{ statisticsData.examPlaceCount }}
            <span>个</span>
          </div>
        </div>
        <img src="../../assets/home/homeTit3.png" />
      </div>
      <div class="homeLi">
        <div class="homeLiLeft">
          <div class="homeLiLeftTit">考场数量</div>
          <div class="homeLiLeftContent">
            {{ statisticsData.examPlaceRoomCount }}
            <span>个</span>
          </div>
        </div>
        <img src="../../assets/home/homeTit4.png" />
      </div>
    </div>
    <!-- 卡片 -->
    <el-card class="box-card" v-if="boxCard">
      <i
        @click="
          () => {
            this.boxCard = false
            this.roomListDialog = false
            this.roomListDialogBottom = false
          }
        "
        class="el-icon-close"
      ></i>
      <div class="cardContent">
        <template v-if="!!boxCardData.isSchool">
          <div class="cardContentText">
            学校
            <span>{{ boxCardData.SchoolDTO.name }}</span>
          </div>
          <div class="cardContentText" v-if="!!boxCardData.SchoolDTO.address">
            <i class="el-icon-map-location"></i>
            {{ boxCardData.SchoolDTO.address }}
          </div>
          <div class="homeLi">
            <div class="homeLiLeft">
              <div class="homeLiLeftTit">班级</div>
              <div class="homeLiLeftContent">
                9
                <span>个</span>
              </div>
            </div>
            <img src="../../assets/home/homeTit1.png" />
          </div>
          <div class="homeLi">
            <div class="homeLiLeft">
              <div class="homeLiLeftTit">学生</div>
              <div class="homeLiLeftContent">
                800
                <span>人</span>
              </div>
            </div>
            <img src="../../assets/home/homeTit1.png" />
          </div>
        </template>
        <div class="cardContentText">
          考点
          <el-button
            v-throttle
            type="primary"
            size="small"
            @click="getRoomList"
            >{{ boxCardData.ExamPlaceDTO.name }}</el-button
          >
        </div>
        <div class="cardContentText" v-if="!!boxCardData.ExamPlaceDTO.address">
          <i class="el-icon-map-location"></i>
          {{ boxCardData.ExamPlaceDTO.address }}
        </div>
      </div>
    </el-card>
    <!-- 考点教室 -->
    <el-card class="roomCard" v-if="roomListDialog">
      <i
        @click="
          () => {
            this.roomListDialog = false
            this.roomListDialogBottom = false
          }
        "
        class="el-icon-close"
      ></i>
      <div class="roomCardContent">
        <template v-if="!roomListDialogList.length">无考场数据</template>
        <template v-else>
          <el-button
            v-throttle
            type="primary"
            plain
            v-for="(val, i) in roomListDialogList"
            :key="i"
            @click="getRoomSeat(val)"
            >{{ val.name }}</el-button
          >
        </template>
      </div>
      <div class="roomCardContentBottom" v-if="roomListDialogBottom">
        <span style="margin: 0 0 10px 0; display: block; font-size: 18px"
          >考场座位:</span
        >
        <div
          class="roomCardContentBottomUl"
          v-for="(val, i) in roomListDialogBottomLis.seatArrange"
          :key="i"
        >
          <div
            class="roomCardContentBottomLi"
            v-for="ele in val"
            :key="ele"
            @click="getVideoNum(ele)"
          >
            {{ ele }}
          </div>
        </div>
      </div>
    </el-card>
    <!-- 视频弹框 -->
    <el-dialog
      :title="dialogVideoTit"
      :visible.sync="dialogVideo"
      width="90%"
      @close="handerClose"
    >
      <!-- 播放器  -->
      <el-row>
        <div>
          <el-col :span="12" style="height: 520px">
            <div class="badge_box">俯拍</div>
            <div id="mainVideo"></div>
          </el-col>
          <el-col :span="12" style="height: 520px">
            <div class="badge_box">正拍</div>
            <div id="subVideo"></div>
          </el-col>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { roomList, placeExamList, statisticsList } from '@/api/home/home.js'
import Map from './components/map.vue'
//直播流
import 'xgplayer'
import FlvJsPlayer from 'xgplayer-flv'
export default {
  name: 'home',
  components: { Map },
  data() {
    return {
      input: '',
      radio: 1,
      scaleNun: 1.7,
      boxCard: true,
      roomListDialog: false,
      roomListDialogList: [],
      roomListDialogBottom: false,
      roomListDialogBottomLis: {},
      // 视频弹框
      dialogVideo: false,
      // 视频弹框标题
      dialogVideoTit: '视频播放',
      //播放器相关
      mainPlayer: null,
      subPlayer: null,
      mainStreamUrl: '',
      subStreamUrl: '',
      // 首页顶数据
      statisticsData: {
        studentCount: 0,
        userCount: 0,
        examPlaceCount: 0,
        examPlaceRoomCount: 0,
      },
      // 坐标
      coordinateTagData: [
        {
          isSchool: 0,
          sysOrgExamPlaceId: 0,
          value: ['113.34669', '23.147482'],
          // 考点对象
          ExamPlaceDTO: {
            name: '',
            address: '',
          },
          // 学校对象
          SchoolDTO: {
            name: '',
            address: '',
          },
        },
      ],
      // 学校考点显示信息
      boxCardData: {
        isSchool: 1,
        sysOrgExamPlaceId: 10000,
        value: ['113.22', '23.38'],
        ExamPlaceDTO: {
          name: '长鹏中学',
          address: '广东省广州市番禺区长鹏中学',
        },
        SchoolDTO: {
          name: '番禺长鹏一学',
          address: '广东省广州市番禺区长鹏中学',
        },
      },
    }
  },
  methods: {
    //播放器相关
    initFlvPlayer(mainStreamUrl, subStreamUrl) {
      this.$nextTick(() => {
        this.mainPlayer = new FlvJsPlayer({
          id: 'mainVideo',
          url: mainStreamUrl,
          //初始化显示视频首帧
          videoInit: true,
          //音量(自动播放需配合静音)
          volume: 0,
          //自动播放
          autoplay: true,
          //循环播放
          // loop: true,
          //关闭控制条
          controls: false,
          closeVideoClick: true,
          // loop: true,
          width: '100%',
          height: '100%',
        })
        this.subPlayer = new FlvJsPlayer({
          id: 'subVideo',
          url: subStreamUrl,
          //初始化显示视频首帧
          videoInit: true,
          //音量(自动播放需配合静音)
          volume: 0,
          //自动播放
          autoplay: true,
          //循环播放
          // loop: true,
          //关闭控制条
          controls: false,
          closeVideoClick: true,
          // loop: true,
          width: '100%',
          height: '100%',
        })
      })
    },
    destroyFlvPlayer() {
      this.mainPlayer.destroy(false)
      this.subPlayer.destroy(false)
    },
    async getRoomList() {
      await roomList(this.boxCardData.sysOrgExamPlaceId).then((res) => {
        console.log('展示某个考点下面所有【考点教室】', res)
        if (res.success) {
          this.roomListDialogList = res.data
          console.log('this.roomListDialogList', this.roomListDialogList)
          this.roomListDialog = true
        }
      })
    },
    getRoomSeat(val) {
      console.log(val)
      console.log(val.seatArrange)
      this.roomListDialogBottomLis = val
      this.roomListDialogBottom = true
    },
    getVideoNum(num) {
      this.dialogVideoTit =
        '视频：' + this.roomListDialogBottomLis.name + '(座位:' + num + ')'
      // this.roomListDialogBottomLis
      // num
      let _num = num > 9 ? num : '0' + num
      this.dialogVideo = true
      this.mainStreamUrl = this.$mediaAddress(
        `/live/${this.roomListDialogBottomLis.code}/${_num}/camera0/stream0.live.flv`
      )
      this.subStreamUrl = this.$mediaAddress(
        `/live/${this.roomListDialogBottomLis.code}/${_num}/camera1/stream0.live.flv`
      )
      this.initFlvPlayer(this.mainStreamUrl, this.subStreamUrl)
    },
    handerClose() {
      this.destroyFlvPlayer()
    },
    // 获取首页统计数据
    getStatisticsList() {
      statisticsList().then((res) => {
        console.log('获取首页统计数据', res)
        if (res.success) {
          this.statisticsData = res.data
        } else {
          // 提示错误
        }
      })
    },
    // 获取考点列表
    getPlaceExamList() {
      this.coordinateTagData = []
      placeExamList().then((res) => {
        console.log('获取考点列表', res)
        if (res.success) {
          res.data.forEach((val) => {
            let li = {
              isSchool: val.isSchool,
              sysOrgExamPlaceId: val.sysOrgExamPlaceId,
              value: [val.sysOrgExamPlaceDTO.lng, val.sysOrgExamPlaceDTO.lat],
              ExamPlaceDTO: {
                name: val.sysOrgExamPlaceDTO.name,
                address: val.sysOrgExamPlaceDTO.address,
              },
              SchoolDTO: {
                name: val.sysOrgSchoolDTO?.name,
                address: val.sysOrgSchoolDTO?.address,
              },
            }
            this.coordinateTagData.push(li)
          })
          // console.log(this.coordinateTagData);
        } else {
          // 提示错误
        }
      })
    },
    // 点击坐标
    tagClick(data) {
      this.boxCard = false
      this.roomListDialog = false
      this.roomListDialogBottom = false
      this.boxCardData = data
      console.log('this.boxCardData', this.boxCardData)
      this.boxCard = true
    },
  },
  // async mounted () {
  //   this.getStatisticsList()
  //   this.getPlaceExamList()
  //   await this.getRoomList()
  //   this.getRoomSeat(this.roomListDialogList[0])
  // },
  destroyed() {
    this.destroyFlvPlayer()
  },
  async created() {
    this.getStatisticsList()
    this.getPlaceExamList()
    await this.getRoomList()
    this.getRoomSeat(this.roomListDialogList[0])
  },
}
</script>
<style lang="scss" scoped>
::v-deep .homeBox {
  position: relative;
}
::v-deep .homeUl {
  width: 100%;
  background: #f5f6fa20;
  // height: 100px;
  box-sizing: border-box;
  padding: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 12px;
  left: 0;
}
::v-deep .homeLi {
  width: 230px;
  // width: 180px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff20;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  .homeLiLeft {
    .homeLiLeftTit {
      color: #ccc;
      font-size: 16px;
      font-weight: bold;
    }
    .homeLiLeftContent {
      font-size: 26px;
      line-height: 30px;
      font-weight: bold;
      letter-spacing: 2px;
      span {
        color: #ccc;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
      }
    }
  }
  img {
    width: 61px;
    height: 68px;
    display: block;
  }
}
.homeLiTextTop {
  font-weight: bold;
  font-size: 30px;
  color: #fff;
  letter-spacing: 4px;
  margin: 0 40px;
  font-family: 'Courier New', Courier, monospace;
}
::v-deep .homeContent {
  width: 100%;
  // height: calc(100% - 114px);
  height: calc(100% + 14px);
  box-sizing: border-box;
  // margin-top: 10px;
  border: 1px solid #d8dce5;
  position: relative;
  overflow: hidden;
  .homeMapBox {
    width: 100%;
    height: 100%;
    position: relative;
    .homeMap {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // background: url(../../assets/home/gz.png) no-repeat center;
      // background-size: contain;
      // transform: scale(1);
      // background-color: #ccc;
    }
    i {
      cursor: pointer;
      z-index: 10;
      position: absolute;
      font-size: 15px;
      // color: red;
      color: red;
      &:hover {
        transform-origin: 16px 29px;
        transform: scale(1.1);
        span {
          display: block;
        }
      }
      span {
        display: none;
        font-size: 12px;
      }
    }
  }

  // .homeContentBtn {
  //   position: absolute;
  //   bottom: 30px;
  //   right: 30px;
  //   z-index: 5;
  //   i {
  //     cursor: pointer;
  //     display: block;
  //     font-size: 20px;
  //     margin-top: 10px;
  //     color: #3385ff;
  //   }
  // }
}
.box-card {
  position: absolute;
  top: 200px;
  left: calc(50% - 200px);
  z-index: 10;
  width: 460px;
  background-color: #f5f6fa20;
  border-color: #cccccc00;
  // backdrop-filter: blur(5px);
  border-radius: 4px;
  padding-top: 20px;
  // pointer-events: none;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .cardContent {
    // background: #ffffff20;
    padding: 10px;
    border-radius: 4px;
    // backdrop-filter: blur(5px);
    color: #fff;
    .homeLi {
      width: 100%;
      padding: 10px 30px;
      border: 1px solid #318ade;
      margin: 10px 0;
      background: transparent;
    }
  }
  .cardContentText {
    font-size: 16px;
    margin-bottom: 8px;
  }
  ::v-deep .el-icon-close {
    margin-top: -22px;
    float: right;
    cursor: pointer;
    color: #fff;
    &:hover {
      color: red;
    }
  }
}
.roomCard {
  position: absolute;
  top: 200px;
  left: calc(50% + 270px);
  z-index: 10;
  width: 300px;
  background-color: #f5f6fa20;
  border-color: #cccccc00;
  // backdrop-filter: blur(5px);
  border-radius: 4px;
  padding-top: 20px;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .roomCardContent {
    background: #ffffff20;
    padding: 10px;
    border-radius: 4px;
    .el-button {
      margin: 0 10px 10px 0;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }
  .roomCardContentBottom {
    background: #ffffff20;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    .roomCardContentBottomUl {
      display: flex;
      border: 1px solid #ccc;
      border-bottom: 0;
      &:last-child {
        border-bottom: 1px solid #ccc;
      }
      .roomCardContentBottomLi {
        border-right: 1px solid #ccc;
        width: 100%;
        flex: 1;
        text-align: center;
        font-size: 20px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        &:last-child {
          border-right: 0px solid #ccc;
        }
        &:hover {
          background: #318ade;
          color: #fff;
        }
      }
    }
  }
  ::v-deep .el-icon-close {
    margin-top: -22px;
    float: right;
    cursor: pointer;
    color: #fff;
    &:hover {
      color: red;
    }
  }
}

::v-deep video {
  object-fit: cover;
}

.badge_box {
  font-size: 17px;
}

// .cardList {
//   position: absolute;
//   top: 0;
//   width: 500px;
//   z-index: 12;
// }
// .cardStatistics {
//   position: absolute;
//   top: 0;
//   width: 500px;
//   z-index: 12;
// }
</style>
