var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homeBox"},[_c('div',{staticClass:"homeContent"},[_c('div',{staticClass:"homeMapBox"},[_c('div',{staticClass:"homeMap"},[_c('Map',{attrs:{"coordinateTagData":_vm.coordinateTagData},on:{"tagClick":_vm.tagClick}})],1)])]),_c('div',{staticClass:"homeUl"},[_c('div',{staticClass:"homeLi"},[_c('div',{staticClass:"homeLiLeft"},[_c('div',{staticClass:"homeLiLeftTit"},[_vm._v("学生数量")]),_c('div',{staticClass:"homeLiLeftContent"},[_vm._v(" "+_vm._s(_vm.statisticsData.studentCount)+" "),_c('span',[_vm._v("人")])])]),_c('img',{attrs:{"src":require("../../assets/home/homeTit1.png")}})]),_c('div',{staticClass:"homeLi"},[_c('div',{staticClass:"homeLiLeft"},[_c('div',{staticClass:"homeLiLeftTit"},[_vm._v("用户数量")]),_c('div',{staticClass:"homeLiLeftContent"},[_vm._v(" "+_vm._s(_vm.statisticsData.userCount)+" "),_c('span',[_vm._v("人")])])]),_c('img',{attrs:{"src":require("../../assets/home/homeTit2.png")}})]),_c('div',{staticClass:"homeLiTextTop"},[_vm._v("市级平台实验考试系统")]),_c('div',{staticClass:"homeLi"},[_c('div',{staticClass:"homeLiLeft"},[_c('div',{staticClass:"homeLiLeftTit"},[_vm._v("考点数量")]),_c('div',{staticClass:"homeLiLeftContent"},[_vm._v(" "+_vm._s(_vm.statisticsData.examPlaceCount)+" "),_c('span',[_vm._v("个")])])]),_c('img',{attrs:{"src":require("../../assets/home/homeTit3.png")}})]),_c('div',{staticClass:"homeLi"},[_c('div',{staticClass:"homeLiLeft"},[_c('div',{staticClass:"homeLiLeftTit"},[_vm._v("考场数量")]),_c('div',{staticClass:"homeLiLeftContent"},[_vm._v(" "+_vm._s(_vm.statisticsData.examPlaceRoomCount)+" "),_c('span',[_vm._v("个")])])]),_c('img',{attrs:{"src":require("../../assets/home/homeTit4.png")}})])]),(_vm.boxCard)?_c('el-card',{staticClass:"box-card"},[_c('i',{staticClass:"el-icon-close",on:{"click":() => {
          this.boxCard = false
          this.roomListDialog = false
          this.roomListDialogBottom = false
        }}}),_c('div',{staticClass:"cardContent"},[(!!_vm.boxCardData.isSchool)?[_c('div',{staticClass:"cardContentText"},[_vm._v(" 学校 "),_c('span',[_vm._v(_vm._s(_vm.boxCardData.SchoolDTO.name))])]),(!!_vm.boxCardData.SchoolDTO.address)?_c('div',{staticClass:"cardContentText"},[_c('i',{staticClass:"el-icon-map-location"}),_vm._v(" "+_vm._s(_vm.boxCardData.SchoolDTO.address)+" ")]):_vm._e(),_c('div',{staticClass:"homeLi"},[_c('div',{staticClass:"homeLiLeft"},[_c('div',{staticClass:"homeLiLeftTit"},[_vm._v("班级")]),_c('div',{staticClass:"homeLiLeftContent"},[_vm._v(" 9 "),_c('span',[_vm._v("个")])])]),_c('img',{attrs:{"src":require("../../assets/home/homeTit1.png")}})]),_c('div',{staticClass:"homeLi"},[_c('div',{staticClass:"homeLiLeft"},[_c('div',{staticClass:"homeLiLeftTit"},[_vm._v("学生")]),_c('div',{staticClass:"homeLiLeftContent"},[_vm._v(" 800 "),_c('span',[_vm._v("人")])])]),_c('img',{attrs:{"src":require("../../assets/home/homeTit1.png")}})])]:_vm._e(),_c('div',{staticClass:"cardContentText"},[_vm._v(" 考点 "),_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"type":"primary","size":"small"},on:{"click":_vm.getRoomList}},[_vm._v(_vm._s(_vm.boxCardData.ExamPlaceDTO.name))])],1),(!!_vm.boxCardData.ExamPlaceDTO.address)?_c('div',{staticClass:"cardContentText"},[_c('i',{staticClass:"el-icon-map-location"}),_vm._v(" "+_vm._s(_vm.boxCardData.ExamPlaceDTO.address)+" ")]):_vm._e()],2)]):_vm._e(),(_vm.roomListDialog)?_c('el-card',{staticClass:"roomCard"},[_c('i',{staticClass:"el-icon-close",on:{"click":() => {
          this.roomListDialog = false
          this.roomListDialogBottom = false
        }}}),_c('div',{staticClass:"roomCardContent"},[(!_vm.roomListDialogList.length)?[_vm._v("无考场数据")]:_vm._l((_vm.roomListDialogList),function(val,i){return _c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],key:i,attrs:{"type":"primary","plain":""},on:{"click":function($event){return _vm.getRoomSeat(val)}}},[_vm._v(_vm._s(val.name))])})],2),(_vm.roomListDialogBottom)?_c('div',{staticClass:"roomCardContentBottom"},[_c('span',{staticStyle:{"margin":"0 0 10px 0","display":"block","font-size":"18px"}},[_vm._v("考场座位:")]),_vm._l((_vm.roomListDialogBottomLis.seatArrange),function(val,i){return _c('div',{key:i,staticClass:"roomCardContentBottomUl"},_vm._l((val),function(ele){return _c('div',{key:ele,staticClass:"roomCardContentBottomLi",on:{"click":function($event){return _vm.getVideoNum(ele)}}},[_vm._v(" "+_vm._s(ele)+" ")])}),0)})],2):_vm._e()]):_vm._e(),_c('el-dialog',{attrs:{"title":_vm.dialogVideoTit,"visible":_vm.dialogVideo,"width":"90%"},on:{"update:visible":function($event){_vm.dialogVideo=$event},"close":_vm.handerClose}},[_c('el-row',[_c('div',[_c('el-col',{staticStyle:{"height":"520px"},attrs:{"span":12}},[_c('div',{staticClass:"badge_box"},[_vm._v("俯拍")]),_c('div',{attrs:{"id":"mainVideo"}})]),_c('el-col',{staticStyle:{"height":"520px"},attrs:{"span":12}},[_c('div',{staticClass:"badge_box"},[_vm._v("正拍")]),_c('div',{attrs:{"id":"subVideo"}})])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }